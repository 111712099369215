import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="IRStrat II"/>
            <Banner title="IRStrat II"/>
            <section className="welcome_section_area propuesta">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">Se enfoca en la óptima estructuración y difusión de su mensaje estratégico
                            (tesis de
                            inversión, historia y trayectoria), en pro de incentivar la valuación y
                            operatividad de sus
                            instrumentos en los mercados financieros. Este paquete incluye lo siguiente:</p>
                    </div>
                    <div className="row servicios service_right_sidebar p-0 mb-5">
                        <div className="col-lg-12">
                            <div id="accordion"
                                 className="panel-group container">
                                 <div className="row">
                                <div className="col-md-6 mb-5">
                                <div className="" role="tab">
                                        <h4 className="panel-title">
                                             Reportes a los mercados y comunicaciones del
                                                día a día

                                        </h4>
                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Elaboración y circle-up de
                                                    reporte trimestral
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Elaboración y circle-up de
                                                    reporte e informe anual
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Elaboración y actualizaciones
                                                    trimestrales de presentaciones a inversionistas
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Actualizaciones trimestrales de
                                                    investment fact sheet
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Asesoría en comunicación y
                                                    estructuración de guía de resultados anual
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Elaboración de comunicados de
                                                    prensa
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Administración de redes
                                                    sociales dedicadas a RI (Twitter y Linkedin del C-Suite)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Punto de contacto para
                                                    inversionistas
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Asesoría en entrevistas (redes
                                                    sociales y medios de comunicación tradicionales)
                                                </li>
                                            </ul>
                                        </div>

                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                            Comunicaciones
                                                del C-Suite

                                        </h4>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Entrenamiento de los
                                                    principales directivos en comunicación a los mercados
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Preparación y asesoría en foros
                                                    y presentaciones a inversionistas
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Elaboración del discurso
                                                    trimestral
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Elaboración del discurso y
                                                    asesoría en comunicaciones especiales (M&As, desinversiones, y otros
                                                    eventos corporativos)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Asesoría en comunicaciones para
                                                    manejo de crisis
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Asesoría en producción de
                                                    mensajes en video
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                           Estructuración
                                                de políticas, procedimientos y métricas

                                        </h4>
                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Estructuración de política de
                                                    divulgación
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Mapeo de principales funciones
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Estructuración del diagrama de
                                                    flujo de las funciones de RI
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Definición de principales
                                                    indicadores de desempeño (KPIs)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Asesoría en gobierno
                                                    corporativo
                                                </li>
                                            </ul>
                                        </div>

                                </div>
                                <div className=" col-md-6 mb-5">
                                <div className="" role="tab">
                                        <h4 className="panel-title">

                                          Núcleo estratégico

                                        </h4>
                                    </div>
                                    <div>


                                        <ul className="list_style">
                                            <li><i className="fa fa-angle-right"></i>Desarrollo de tesis de
                                                inversión
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Estructuración y narrativa de
                                                ventaja competitiva
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Desarrollo de la narrativa de
                                                la historia
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Estructuración de la guía de
                                                resultados a largo plazo
                                            </li>
                                            <li><i className="fa fa-angle-right"></i>Definición de estilo de
                                                comunicación (mix cualitativo y cuantitativo)
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                            Difusión y divulgación estratégica

                                        </h4>
                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Estrategia en envíos de wires
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Elaboración y envío de anuncios
                                                    de comunicados trimestrales de resultados e invitaciones a
                                                    conferencias de resultados
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Divulgación a medios
                                                    financieros y especializados
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Administración de listas de
                                                    distribución
                                                </li>
                                            </ul>

                                    </div>
                                </div>
                                <div className=" col-md-6 mb-5">
                                    <div className="" role="tab">
                                        <h4 className="panel-title">
                                           Servicios IR Edge 3.0
                                            </h4>

                                    </div>

                                        <div className="panel-body">

                                            <ul className="list_style">
                                                <li><i className="fa fa-angle-right"></i>Capacidad de distribución de
                                                    hasta 10,000 contactos
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Herramientas de análisis de
                                                    correo electrónico
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Servicios de traducción
                                                    (español-inglés)
                                                </li>
                                                <li><i className="fa fa-angle-right"></i>Logística de webcast (edición
                                                    de MP3 y transcripción)
                                                </li>
                                            </ul>
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </section>

        </Layout>
    )
}

export default Contenido
